import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Container from '../components/container';
import styled from 'styled-components';
import { PlainPageTitle, PlainPageHeader } from '../components/common';

const TwoColumn = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 20px 0 30px 0;
`;

const ColumnTitle = styled.div`
  font-weight: bold;
  font-family: 'Gotham-Bold', sans-serif;
  padding-bottom: 10px;
`;

const Column = styled.div`
  flex: 1;
`;

const Cell = styled.div`
  height: 18px;
  margin-bottom: 5px;
  border-bottom: 1px solid transparent;
`;

const ColumnContent = styled.div`
  display: flex;
`;

const EmptyLine = styled.div`
  margin-left: 20px;
  height: 18px;
  margin-bottom: 5px;
  width: 200px;
  border-bottom: 1px solid black;
`;

const CenteredHeader = styled(PlainPageHeader)`
  text-align: center;
  margin: 20px 0;
`;

const UnderlinedHeader = styled.div`
  text-decoration: underline;
  margin: 10px 0;
`;

const BoldedHeader = styled.div`
  font-weight: bold;
  font-family: 'Gotham-Bold', sans-serif;
  margin: 10px 0;
`;

const ItalicizedHeader = styled.div`
  font-style: italic;
  margin: 10px 0;
`;

const CenteredItalicizedHeader = styled(ItalicizedHeader)`
  text-align: center;
`;

const CenteredBoldedHeader = styled(BoldedHeader)`
  text-align: center;
`;

const DPA = () => (
  <Layout>
    <SEO
      title='Data Processing Agreement'
      keywords={[`percept.ai`, `customer support`, `artifical intelligence`]}
    />
    <Container>
      <PlainPageTitle>Data Processing Agreement</PlainPageTitle>
      <p>
        This <b>Data Processing Agreement</b> (“DPA”) is entered into between
        Buddy AI Inc. (“<b>Percept AI</b>”) and Customer identified below (“
        <b>Customer</b>”), and forms a part of the applicable Service Agreement,
        Pilot Agreement or Data Evaluation (defined below), as the case may be,
        “<b>Agreement</b>”) between the Parties, and reflects the Parties’
        agreement with regard to the processing of Personal Data (as defined
        below) in accordance with the requirements of the Data Protection Laws
        (as defined below). Percept AI and Customer are each individually
        referred to as a “<b>Party</b>” and together as the “<b>Parties.</b>”
      </p>
      <p>
        This DPA is effective on the date that it has been duly executed by both
        Parties (“Effective Date”), and amends, supersedes and replaces any
        prior data processing agreements that the Parties may have been entered
        into. Any modifications to the terms of this DPA (whether handwritten or
        otherwise) will render this DPA ineffective unless Percept AI has
        separately agreed to those modifications in writing.
      </p>
      <PlainPageHeader>1. Introduction</PlainPageHeader>
      <p>
        This DPA sets out terms that apply to Percept AI’s Processing of
        Customer Data (as defined below) including without limitation Personal
        Data (as defined below) under the Agreement.
      </p>
      <PlainPageHeader>2. Definitions</PlainPageHeader>
      <ol type='a'>
        <li>
          "Customer Data" means non-public data provided by Customer to Company
          to enable the provision of the services or data evaluation.
        </li>
        <li>
          "Data Controller" means the party that determines the purposes and
          means of the Processing of Personal Data.
        </li>
        <li>
          will scan through all past support data provided by Customer to
          identify repetitive customer issues and extract common responses used
          by Customer’s human agents.
        </li>
        <li>
          "Data Processor" means the party that Processes Personal Data on
          behalf of, or under the instruction of, the Data Controller.
        </li>
        <li>
          "Data Protection Authority" means the competent body in the
          jurisdiction charged with enforcement of applicable Data Protection
          Law.
        </li>
        <li>
          "Data Protection Laws" means with respect to a party, all privacy,
          data protection, information security and other related laws and
          regulations applicable to such party, including, where applicable, EU
          Data Protection Law.
        </li>
        <li>
          "Data Subject" means the identified or identifiable person who is the
          subject of Personal Data.
        </li>
        <li>
          “EU Data Protection Law” means European Union Regulation 2016/679
          (“GDPR”).
        </li>
        <li>
          References to "instructions" or "written instructions" and related
          terms mean Data Controller’s instructions for Processing of Customer
          Data, which consist of (1) the terms of the Agreement and this DPA,
          (2) Processing enabled by Data Controller through the Service, and (3)
          other reasonable written instructions of Data Controller consistent
          with the terms of the DPA.
        </li>
        <li>
          "Personal Data" means any information included in the Customer Data
          relating to an identified or identifiable natural person; an
          identifiable person is one who can be identified, directly or
          indirectly, in particular by reference to an identification number or
          to one or more factors specific to their physical, physiological,
          mental, economic, cultural or social identity.
        </li>
        <li>
          "Processing" has the meaning given to it in the applicable EU Data
          Protection Law and "process", "processes" and "processed" will be
          interpreted accordingly.
        </li>
        <li>
          "Proprietary Information" means business, technical or financial
          information relating to a Party’s business including non-public
          information and data.
        </li>
        <li>
          "Security Incident" means any unauthorized or unlawful confirmed
          breach of security that leads to the accidental or unlawful
          destruction, loss, alteration, unauthorized disclosure of or access to
          Personal Data in Data Processor’s control.
        </li>
        <li>
          "Service" means provision of one or more services provided by Percept
          AI pursuant to the Agreement through the Percept AI Platform Service.
        </li>
        <li>
          "Standard Contractual Clauses/EU Standard Contractual Clauses" mean
          the standard contractual clauses set forth in Schedule 1 for the
          transfer of Personal Data from a Data Controller in the European
          Economic Area to Processors established in third countries in the form
          set out in the Annex of European Commission Decision 2010/87/EU, as
          amended by incorporating the description of the Personal Data to be
          transferred and the technical and organizational measures to be
          implemented as set out in the Appendix.
        </li>
        <li>
          "Subprocessor" means any Third Party engaged by Data Processor or its
          affiliates to process any Customer Data pursuant to the Agreement or
          this DPA.
        </li>
        <li>
          "Third Party" shall mean any natural or legal person, public
          authority, agency or any other body other than the Data Subject, Data
          Controller, Data Processor, or Subprocessors or other persons who,
          under the direct authority of the Data Controller or Data Processor,
          are authorized to Process the data.
        </li>
        <li>
          Other capitalized terms not defined herein have the meanings given in
          the Agreement.
        </li>
      </ol>
      <PlainPageHeader>3. General Termination</PlainPageHeader>
      <ol type='a'>
        <li>
          This DPA forms part of the Agreement and except as expressly set forth
          in this DPA, the Agreement remains unchanged and in full force and
          effect. If there is any conflict between this DPA and the Agreement,
          this DPA shall prevail to the extent of that conflict in connection
          with the Processing of Customer Data.
        </li>
        <li>
          All activities under this DPA (including without limitation Processing
          of Customer Data) remain subject to the applicable limitations of
          liability set forth in the Agreement.
        </li>
        <li>
          This DPA will be governed by and construed in accordance with
          governing law and jurisdiction provisions in the Agreement, unless
          required otherwise by applicable Data Protection Laws.
        </li>
        <li>
          This DPA will automatically terminate upon expiration or termination
          of the Agreement.
        </li>
      </ol>
      <PlainPageHeader>4. Role and Scope of the Processing</PlainPageHeader>
      <ol type='a'>
        <li>
          Customer will act as the Data Controller and Percept AI will act as
          the Data Processor under this DPA. Both Customer and Percept AI shall
          be subject to applicable Data Protection Laws in the carrying out of
          their responsibilities as set forth in this DPA.
        </li>
        <li>
          Customer retains all ownership rights in the Customer Data, as set
          forth in the Agreement. Except as expressly authorized by Customer in
          writing or as instructed by Customer, Percept AI shall have no right
          directly or indirectly to sell, rent, lease, combine, display,
          perform, modify, transfer or disclose the Customer Data or any
          derivative work thereof. Percept AI shall act only in accordance with
          Customer’s instructions regarding the Processing of the Customer Data
          except to the extent prohibited by applicable Data Protection Laws.
        </li>
        <li>
          Additional instructions not consistent with the scope of the Agreement
          require prior written agreement of the Parties, including agreement on
          any additional fees payable by Customer.
        </li>
        <li>
          Notwithstanding the above, Customer acknowledges that Percept AI shall
          have a right to use Aggregated Anonymized Data. "Aggregated Anonymized
          Data" means information concerning Customer Data and data derived
          therefrom and any suggestions, comments or other feedback given about
          the Services combined with any data or information of Percept AI or
          any third party and used in an anonymized or de-identified form.
        </li>
        <li>
          Percept AI shall not disclose the Customer Data to any Third Party in
          any circumstances other than in compliance with Customer’s
          instructions or in compliance with a legal obligation to disclose.
          Percept AI shall inform Customer in writing prior to making any such
          legally required disclosure, to the extent permitted by Data
          Protection Laws.
        </li>
        <li>
          For clarity, nothing in this DPA limits Percept AI from transmitting
          Customer Data (including without limitation Personal Data) as
          instructed by Customer through the Service.
        </li>
      </ol>
      <PlainPageHeader>5. Subprocessing</PlainPageHeader>
      <ol type='a'>
        <li>
          Customer agrees that Percept AI is authorized to use Subprocessors
          (including without limitation cloud infrastructure providers) to
          Process the Personal Data, provided that Percept AI: (i) enters into a
          written agreement with any Subprocessor, imposing data protection
          obligations substantially similar to this DPA; and (ii) remains liable
          for compliance with the obligations of this DPA and for any acts or
          omissions of the Subprocessor that cause Percept AI to breach any of
          its obligations under this DPA.
        </li>
        <li>
          Information about Subprocessors, including their functions and
          locations, is available at:{' '}
          <a href='https://percept.ai/gdpr'>https://percept.ai/gdpr</a> (as may
          be updated by Percept AI from time to time in accordance with this
          DPA).
        </li>
      </ol>
      <PlainPageHeader>6. Security</PlainPageHeader>
      <ol type='a'>
        <li>
          Percept AI shall implement and maintain appropriate technical and
          organizational security measures to protect Personal Data from
          Security Incidents and to preserve the security and confidentiality of
          the Personal Data, in accordance with Percept AI's security standards
          described in Appendix 2 ("Security Measures").
        </li>
        <li>
          Customer is responsible for reviewing the information made available
          by Percept AI relating to data security and making an independent
          determination as to whether the Service meets the Customer's
          requirements and legal obligations under Data Protection Laws.
          Customer acknowledges that the Security Measures are subject to
          technical progress and that Percept AI may update or modify the
          Security Measures from time to time provided that such updates and
          modifications do not result in the degradation of the overall security
          of the Service purchased by Customer.
        </li>
      </ol>
      <PlainPageHeader>7. Regulatory Compliance</PlainPageHeader>
      <ol type='a'>
        <li>
          At Customer’s request and expense, Percept AI shall reasonably assist
          Customer as necessary to meet its obligations to Data Protection
          Authorities.
        </li>
        <li>
          Percept AI shall (at Customer's expense) reasonably assist Customer to
          respond to requests from individuals in relation to their rights of
          data access, rectification, erasure, restriction, portability and
          objection. In the event that any such request is made directly to
          Percept AI, Percept AI shall not respond to such communication
          directly without Customer's prior authorization unless required by
          Data Protection Laws. Information on how to initiate a request is
          available here:{' '}
          <a href='https://percept.ai/gdpr'>https://percept.ai/gdpr</a>
        </li>
      </ol>
      <PlainPageHeader>8. Reviews of Data Processing</PlainPageHeader>
      <ol type='a'>
        <li>
          At Customer’s request, Percept AI shall provide Customer with written
          responses to all reasonable requests for information made by Customer
          relevant to the Processing of Personal Data under this DPA, including
          responses to security and audit questionnaires, in each case solely to
          the extent necessary to confirm Percept AI’s compliance with this DPA.
        </li>
        <li>
          Except as expressly required by Data Protection Laws, any review under
          this Section 8 will: (i) be conducted no more often than once per year
          during Percept AI’s normal business hours, in a manner so as not to
          interfere with standard business operations; (ii) be subject to
          Percept AI’s reasonable confidentiality and security processes and
          procedures; (iii) be conducted at Customer’s expense; and (iv) not
          extend to any information, systems or facilities of Percept AI’s other
          customers or its Third Party infrastructure providers.
        </li>
        <li>
          Any information provided by Percept AI under this Section 8
          constitutes Percept AI’s Proprietary Information under the Agreement.
        </li>
      </ol>
      <PlainPageHeader>9. Return or deletion of data</PlainPageHeader>
      <ol type='a'>
        <li>
          Percept AI shall, within one hundred and eighty (180) days after
          request by Customer at the termination or expiration of the Agreement,
          delete or return, at Customer’s choice, all of the Personal Data from
          Percept AI’s systems. Within a reasonable period following deletion,
          at Customer’s request, Percept AI will provide written confirmation
          that Percept AI’s obligations of data deletion or destruction have
          been fulfilled.
        </li>
        <li>
          Notwithstanding the foregoing, Customer understands that Percept AI
          may retain Customer Data as required by Data Protection Laws, which
          data will remain subject to the requirements of this DPA.
        </li>
      </ol>
      <p>
        <b>IN WITNESS WHEREOF</b>, the Parties acknowledge their agreement to
        the foregoing by due execution of the Agreement by their respective
        authorized representatives.
      </p>
      <TwoColumn>
        <Column>
          <ColumnTitle>[Customer name]</ColumnTitle>
          <ColumnContent>
            <div>
              <Cell>By:</Cell>
              <Cell>Name:</Cell>
              <Cell>Title:</Cell>
              <Cell>Date:</Cell>
            </div>
            <div>
              <EmptyLine></EmptyLine>
              <EmptyLine></EmptyLine>
              <EmptyLine></EmptyLine>
              <EmptyLine></EmptyLine>
            </div>
          </ColumnContent>
        </Column>
        <Column>
          <ColumnTitle>Buddy AI Inc.</ColumnTitle>
          <ColumnContent>
            <div>
              <Cell>By:</Cell>
              <Cell>Name:</Cell>
              <Cell>Title:</Cell>
              <Cell>Date:</Cell>
            </div>
            <div>
              <EmptyLine></EmptyLine>
              <EmptyLine></EmptyLine>
              <EmptyLine></EmptyLine>
              <EmptyLine></EmptyLine>
            </div>
          </ColumnContent>
        </Column>
      </TwoColumn>
      <CenteredHeader>APPENDIX 1</CenteredHeader>
      <CenteredHeader>DATA PROCESSING DESCRIPTION</CenteredHeader>
      <UnderlinedHeader>
        Subject-matter and duration of the Processing:
      </UnderlinedHeader>
      <p>
        The subject-matter of the Processing is Percept AI’s provision of the
        services is set forth in the Agreement. The duration of the Processing
        is set forth in the Agreement.
      </p>
      <UnderlinedHeader>
        Nature and purposes of the Processing:
      </UnderlinedHeader>
      <p>
        During the pre-launch phase, since training the AI algorithm only
        requires knowledge about how a customer issue is resolved, no personal
        data is collected or processed.
      </p>
      <p>
        During the post-launch phase, based on the required use case, the AI
        system might need to collect and store user email addresses or other
        alternative information that allows the user activities to be linked
        with Customer’s internal user profile system.
      </p>
      <UnderlinedHeader>Categories of data</UnderlinedHeader>
      <p>Personal Data includes the following categories of data:</p>
      <ul>
        <li>Pre-launch: No personal data is required</li>
        <li>Post-launch: Email addresses</li>
      </ul>
      <UnderlinedHeader>Data Subjects</UnderlinedHeader>
      <p>Personal Data includes the following categories of Data Subjects:</p>
      <ul>
        <li>End users of Customer</li>
      </ul>
      <p>If the EU Standard Contractual Clauses apply:</p>
      <ul style={{ listStyleType: 'none' }}>
        <li>
          <b>Data Exporter</b>
        </li>
        <li>
          The Data Exporter is the entity identified as “Data Controller” in the
          Data Processing Agreement.
        </li>
      </ul>
      <ul style={{ listStyleType: 'none' }}>
        <li>
          <b>Data Importer</b>
        </li>
        <li>
          The Data Importer is the entity identified as "Data Processor" in the
          Data Processing Agreement.
        </li>
      </ul>
      <UnderlinedHeader>Data collection</UnderlinedHeader>
      <p>
        The requested data will be retrieved through Customer ticketing system.
        The following categories of data objects will be required:
      </p>
      <ul>
        <li>Case or Ticket objects</li>
        <li>Email message objects</li>
      </ul>
      <UnderlinedHeader>Data minimization</UnderlinedHeader>
      <p>
        During the pre-launch AI training phase, to ensure that no sensitive
        customer data is accidentally accessed as part of the ticket content, we
        enforce the industry-standard data minimization technique to remove all
        occurrences of potentially sensitive data in memory before any of the
        ticket data is processed and stored in our system.
      </p>
      <p>
        Here are some examples of the type of sensitive information that will be
        immediately removed once detected:
      </p>
      <ul>
        <li>First and last name</li>
        <li>Street address</li>
        <li>Phone and fax number</li>
        <li>Credit and debit card number</li>
        <li>Social Security Number or applicable national identification</li>
        <li>All elements of dates</li>
        <li>Login and password</li>
      </ul>
      <CenteredHeader>APPENDIX 2</CenteredHeader>
      <CenteredHeader>DATA SECURITY MEASURES</CenteredHeader>
      <BoldedHeader>Introduction</BoldedHeader>
      <p>
        Percept AI considers protection of Customer Data a top priority. As
        further described in these Security Measures, Percept AI uses
        commercially reasonable organizational and technical measures designed
        to prevent unauthorized access, use, alteration or disclosure of
        Customer Data stored on systems under Percept AI’s control.
      </p>
      <BoldedHeader>Access Management</BoldedHeader>
      <p>
        Percept AI limits its personnel’s access to Customer Data as follows:
      </p>
      <ol type='a'>
        <li>
          Requires unique user access authorization through secure logins and
          passwords, including multi-factor authentication for Cloud Hosting
          administrator access and individually-assigned Secure Socket Shell
          (SSH) keys for external engineer access;
        </li>
        <li>
          Limits the Customer Data available to Percept AI personnel on a "need
          to know" basis;
        </li>
        <li>
          Restricts access to Percept AI’s production environment by Percept AI
          personnel on the basis of business need; and
        </li>
        <li>Encrypts user security credentials for production access.</li>
      </ol>
      <BoldedHeader>Data Encryption</BoldedHeader>
      <p>
        Percept AI provides industry-standard encryption for Customer Data as
        follows:
      </p>
      <ol type='a'>
        <li>Customer Data is encrypted over the internet; and</li>
        <li>
          Uses strong encryption methodologies to protect Customer Data,
          including AES 256-bit encryption for Customer Data stored in Percept
          AI’s production environment.
        </li>
      </ol>
      <BoldedHeader>Physical &amp; Network Security</BoldedHeader>
      <ol type='a'>
        <li>
          Percept AI uses a variety of techniques designed to detect and/or
          prevent unauthorized access to systems processing Customer Data,
          including firewalls and network access controls.
        </li>
        <li>
          Percept AI maintains measures designed to assess, test and apply
          security patches to all relevant systems and applications used to
          provide the Service.
        </li>
        <li>
          Percept AI monitors privileged access to applications that process
          Customer Data, including cloud services.
        </li>
        <li>
          The Service operates on Amazon Web Services ("AWS") and is protected
          by Amazon’s security and environmental controls. Detailed information
          about AWS security is available at:
        </li>
        <ol type='i'>
          <li>
            <a href='https://aws.amazon.com/security/'>
              https://aws.amazon.com/security/
            </a>
          </li>
          <li>
            <a href='https://aws.amazon.com/compliance/soc-faqs/'>
              https://aws.amazon.com/compliance/soc-faqs/
            </a>
          </li>
        </ol>
      </ol>
      <BoldedHeader>Independent Security Assessments</BoldedHeader>
      <p>
        Percept AI periodically assesses the security of its systems and the
        Service as follows:
      </p>
      <ol type='a'>
        <li>
          Ongoing detailed security and vulnerability assessments of the Service
          conducted by independent third-party security and compliance
          professionals. Percept AI shall attest to Customer the date of the
          most recent security and vulnerability assessment at Customer’s
          reasonable request.
        </li>
        <li>
          Automated weekly vulnerability scanning, including automated static
          code analysis of any new code added to the system.
        </li>
      </ol>
      <BoldedHeader>Incident Response</BoldedHeader>
      <p>
        If Percept AI becomes aware of a Security Incident, Percept AI will:
      </p>
      <ol type='a'>
        <li>
          Take reasonable measures to mitigate the harmful effects of the
          Security Incident and prevent further unauthorized access or
          disclosure.
        </li>
        <li>
          Upon confirmation of the Security Incident, notify Customer in writing
          of the Security Incident without undue delay. Notwithstanding the
          foregoing, Percept AI is not required to make such notice to the
          extent prohibited by Data Protection Laws, and Percept AI may delay
          such notice as requested by law enforcement and/or in light of Percept
          AI’s legitimate needs to investigate or remediate the matter before
          providing notice.
        </li>
        <li>Each notice of a Security Incident will include:</li>
        <ol type='i'>
          <li>
            The extent to which Customer Data has been, or is reasonably
            believed to have been, used, accessed, acquired or disclosed during
            the Security Incident;
          </li>
          <li>
            A description of what happened, including the date of the Breach and
            the date of discovery of the Security Incident, if known;
          </li>
          <li>The scope of the Security Incident, to the extent known; and</li>
          <li>
            A description of Percept AI’s response to the Security Incident,
            including steps Percept AI has taken to mitigate the harm caused by
            the Security Incident.
          </li>
        </ol>
      </ol>
      <BoldedHeader>Business Continuity Management</BoldedHeader>
      <ol type='a'>
        <li>
          Percept AI maintains processes to ensure failover redundancy with its
          systems, networks and data storage.
        </li>
      </ol>
      <BoldedHeader>Personnel Management</BoldedHeader>
      <ol type='a'>
        <li>
          Percept AI performs employment verification, including proof of
          identity validation and criminal background checks for all new hires.
        </li>
        <li>
          Percept AI provides training for its personnel who are involved in the
          processing of the Customer Data to ensure they do not collect, process
          or use Customer Data without authorization and that they keep Customer
          Data confidential.
        </li>
        <li>
          Percept AI conducts routine and random monitoring of employee systems
          activity.
        </li>
        <li>
          Upon employee termination, whether voluntary or involuntary, Percept
          AI immediately disables all access to critical and noncritical
          systems, including Percept AI’s physical facilities.
        </li>
      </ol>
      <CenteredHeader>
        Schedule 1 to the Data Processing Agreement
      </CenteredHeader>
      <CenteredHeader>Standard Contractual Clauses (processors)</CenteredHeader>
      <p>
        <b>
          For the purposes of Article 26(2) of Directive 95/46/EC for the
          transfer of personal data to processors established in third countries
          which do not ensure an adequate level of data protection
        </b>
      </p>
      <p>
        <b>The Customer accepting the Clauses (the “Data Exporter”)</b>
      </p>
      <p>And</p>
      <p>
        <b>Buddy AI Inc.,</b> <br />
        <b>440 N Wolfe Rd, Sunnyvale CA, US</b> <br />
        <b>(the “Data Importer”)</b>
      </p>
      <p>each a “party”; together “the parties”,</p>
      <p>
        AGREE on the following Contractual Clauses (the Clauses) in order to
        adduce adequate safeguards with respect to the protection of privacy and
        fundamental rights and freedoms of individuals for the transfer by the
        Data Exporter to the Data Importer of the personal data specified in
        Appendix 1.
      </p>
      <CenteredItalicizedHeader>Clause 1</CenteredItalicizedHeader>
      <CenteredBoldedHeader>Definitions</CenteredBoldedHeader>
      <p>For the purposes of the Clauses:</p>
      <p>
        (a) ‘<b>personal data</b>’, ‘<b>special categories of data</b>’, ‘
        <b>process/processing</b>’, ‘<b>controller</b>’, ‘<b>processor</b>’, ‘
        <b>Data Subject</b>’ and ‘<b>Supervisory Authority</b>’ shall have the
        same meaning as in Directive 95/46/EC of the European Parliament and of
        the Council of 24 October 1995 on the protection of individuals with
        regard to the processing of personal data and on the free movement of
        such data;
      </p>
      <p>
        (b) ‘<b>the Data Exporter</b>’ means the controller who transfers the
        personal data;
      </p>
      <p>
        (c) ‘the <b>Data Importer’</b> means the processor who agrees to receive
        from the Data Exporter personal data intended for processing on his
        behalf after the transfer in accordance with his instructions and the
        terms of the Clauses and who is not subject to a third country’s system
        ensuring adequate protection within the meaning of Article 25 (1) of
        Directive 95/46/EC;
      </p>
      <p>
        (d) ‘the <b>Subprocessor’</b> means any processor engaged by the Data
        Importer or by any other subprocessor of the Data Importer who agrees to
        receive from the Data Importer or from any other subprocessor of the
        Data Importer personal data exclusively intended for processing
        activities to be carried out on behalf of the Data Exporter after the
        transfer in accordance with his instructions, the terms of the Clauses
        and the terms of the written subcontract;
      </p>
      <p>
        (e) ‘the <b>applicable data protection law’</b> means the legislation
        protecting the fundamental rights and freedoms of individuals and, in
        particular, their right to privacy with respect to the processing of
        personal data applicable to a data controller in the Member State in
        which the Data Exporter is established;
      </p>
      <p>
        (f) <b>‘technical and organizational security measures’</b> means those
        measures aimed at protecting personal data against accidental or
        unlawful destruction or accidental loss, alteration, unauthorized
        disclosure or access, in particular where the processing involves the
        transmission of data over a network, and against all other unlawful
        forms of processing.
      </p>

      <CenteredItalicizedHeader>Clause 2</CenteredItalicizedHeader>
      <CenteredBoldedHeader>Details of the transfer</CenteredBoldedHeader>
      <p>
        The details of the transfer and in particular the special categories of
        personal data where applicable are specified in Appendix 1 which forms
        an integral part of the Clauses.
      </p>

      <CenteredItalicizedHeader>Clause 3</CenteredItalicizedHeader>
      <CenteredBoldedHeader>
        Third-party beneficiary clause
      </CenteredBoldedHeader>
      <p>
        1. The Data Subject can enforce against the Data Exporter this Clause,
        Clause 4(b) to (i), Clause 5(a) to (e), and (g) to (j), Clause 6(1) and
        (2), Clause 7, Clause 8(2), and Clauses 9 to 12 as third-party
        beneficiary.
      </p>
      <p>
        2. The Data Subject can enforce against the Data Importer this Clause,
        Clause 5(a) to (e) and (g), Clause 6, Clause 7, Clause 8(2), and Clauses
        9 to 12, in cases where the Data Exporter has factually disappeared or
        has ceased to exist in law unless any successor entity has assumed the
        entire legal obligations of the Data Exporter by contract or by
        operation of law, as a result of which it takes on the rights and
        obligations of the Data Exporter, in which case the Data Subject can
        enforce them against such entity.
      </p>
      <p>
        3. The Data Subject can enforce against the Subprocessor this Clause,
        Clause 5(a) to (e) and (g), Clause 6, Clause 7, Clause 8(2), and Clauses
        9 to 12, in cases where both the Data Exporter and the Data Importer
        have factually disappeared or ceased to exist in law or have become
        insolvent, unless any successor entity has assumed the entire legal
        obligations of the Data Exporter by contract or by operation of law as a
        result of which it takes on the rights and obligations of the data
        exporter, in which case the Data Subject can enforce them against such
        entity. Such third-party liability of the Subprocessor shall be limited
        to its own processing operations under the Clauses.
      </p>
      <p>
        4. The parties do not object to a Data Subject being represented by an
        association or other body if the Data Subject so expressly wishes and if
        permitted by national law.
      </p>

      <CenteredItalicizedHeader>Clause 4</CenteredItalicizedHeader>

      <CenteredBoldedHeader>
        Obligations of the Data Exporter
      </CenteredBoldedHeader>
      <p>The Data Exporter agrees and warrants:</p>
      <p>
        (a) that the processing, including the transfer itself, of the personal
        data has been and will continue to be carried out in accordance with the
        relevant provisions of the applicable data protection law (and, where
        applicable, has been notified to the relevant authorities of the Member
        State where the Data Exporter is established) and does not violate the
        relevant provisions of that State
      </p>
      <p>
        (b) that it has instructed and throughout the duration of the personal
        data processing services will instruct the Data Importer to process the
        personal data transferred only on the Data Exporter’s behalf and in
        accordance with the applicable data protection law and the Clauses;
      </p>
      <p>
        (c) that the Data Importer will provide sufficient guarantees in respect
        of the technical and organizational security measures specified in
        Appendix 2 to this contract;
      </p>
      <p>
        (d) that after assessment of the requirements of the applicable data
        protection law, the security measures are appropriate to protect
        personal data against accidental or unlawful destruction or accidental
        loss, alteration, unauthorized disclosure or access, in particular where
        the processing involves the transmission of data over a network, and
        against all other unlawful forms of processing, and that these measures
        ensure a level of security appropriate to the risks presented by the
        processing and the nature of the data to be protected having regard to
        the state of the art and the cost of their implementation.
      </p>
      <p>(e) that it will ensure compliance with the security measures;</p>
      <p>
        (f) that, if the transfer involves special categories of data, the Data
        Subject has been informed or will be informed before, or as soon as
        possible after, the transfer that its data could be transmitted to a
        third country not providing adequate protection within the meaning of
        Directive 95/46/EC;
      </p>
      <p>
        (g) to forward any notification received from the Data Importer or any
        Subprocessor pursuant to Clause 5(b) and Clause 8(3) to the data
        protection supervisory authority if the Data Exporter decides to
        continue the transfer or to lift the suspension;
      </p>
      <p>
        (h) to make available to the Data Subjects upon request a copy of the
        Clauses, with the exception of Appendix 2, and a summary description of
        the security measures, as well as a copy of any contract for
        sub-processing services which has to be made in accordance with the
        Clauses, unless the Clauses or the contract contain commercial
        information, in which case it may remove such commercial information;
      </p>
      <p>
        (i) that, in the event of sub-processing, the processing activity is
        carried out in accordance with Clause 11 by a Subprocessor providing at
        least the same level of protection for the personal data and the rights
        of Data Subject as the Data Importer under the Clauses; and
      </p>
      <p>(j) that it will ensure compliance with Clause 4(a) to (i).</p>

      <CenteredItalicizedHeader>Clause 5</CenteredItalicizedHeader>
      <CenteredBoldedHeader>
        Obligations of the Data Importer
      </CenteredBoldedHeader>
      <p>The Data Importer agrees and warrants:</p>
      <p>
        (a) to process the personal data only on behalf of the Data Exporter and
        in compliance with its instructions and the Clauses; if it cannot
        provide such compliance for whatever reasons, it agrees to inform
        promptly the Data Exporter of its inability to comply, in which case the
        Data Exporter is entitled to suspend the transfer of data and/or
        terminate the contract;
      </p>
      <p>
        (b) that it has no reason to believe that the legislation applicable to
        it prevents it from fulfilling the instructions received from the Data
        Exporter and its obligations under the contract and that in the event of
        a change in this legislation which is likely to have a substantial
        adverse effect on the warranties and obligations provided by the
        Clauses, it will promptly notify the change to the Data Exporter as soon
        as it is aware, in which case the Data Exporter is entitled to suspend
        the transfer of data and/or terminate the contract;
      </p>
      <p>
        (c) that it has implemented the technical and organizational security
        measures specified in Appendix 2 before processing the personal data
        transferred;
      </p>
      <p>(d) that it will promptly notify the Data Exporter about:</p>
      <p>
        (i) any legally binding request for disclosure of the personal data by a
        law enforcement authority unless otherwise prohibited, such as a
        prohibition under criminal law to preserve the confidentiality of a law
        enforcement investigation;
      </p>
      <p>(ii) any accidental or unauthorized access; and</p>
      <p>
        (iii) any request received directly from the Data Subjects without
        responding to that request, unless it has been otherwise authorized to
        do so;
      </p>
      <p>
        (e) to deal promptly and properly with all inquiries from the Data
        Exporter relating to its processing of the personal Data Subject to the
        transfer and to abide by the advice of the supervisory authority with
        regard to the processing of the data transferred;
      </p>
      <p>
        (f) at the request of the Data Exporter to submit its data-processing
        facilities for audit of the processing activities covered by the Clauses
        which shall be carried out by the Data Exporter or an inspection body
        composed of independent members and in possession of the required
        professional qualifications bound by a duty of confidentiality, selected
        by the Data Exporter, where applicable, in agreement with the
        supervisory authority;
      </p>
      <p>
        (g) to make available to the Data Subject upon request a copy of the
        Clauses, or any existing contract for sub-processing, unless the Clauses
        or contract contain commercial information, in which case it may remove
        such commercial information, with the exception of Appendix 2 which
        shall be replaced by a summary description of the security measures in
        those cases where the Data Subject is unable to obtain a copy from the
        Data Exporter;
      </p>
      <p>
        (h) that, in the event of sub-processing, it has previously informed the
        Data Exporter and obtained its prior written consent;
      </p>
      <p>
        (i) that the processing services by the Subprocessor will be carried out
        in accordance with Clause 11;
      </p>
      <p>
        (j) to send promptly a copy of any Subprocessor agreement it concludes
        under the Clauses to the Data Exporter.
      </p>

      <CenteredItalicizedHeader>Clause 6</CenteredItalicizedHeader>
      <CenteredBoldedHeader>Liability</CenteredBoldedHeader>
      <p>
        1. The parties agree that any Data Subject, who has suffered damage as a
        result of any breach of the obligations referred to in Clause 3 or in
        Clause 11 by any party or Subprocessor is entitled to receive
        compensation from the Data Exporter for the damage suffered.
      </p>
      <p>
        2. If a Data Subject is not able to bring a claim for compensation in
        accordance with paragraph 1 against the Data Exporter, arising out of a
        breach by the Data Importer or his Subprocessor of any of their
        obligations referred to in Clause 3 or in Clause 11, because the Data
        Exporter has factually disappeared or ceased to exist in law or has
        become insolvent, the Data Importer agrees that the Data Subject may
        issue a claim against the Data Importer as if it were the Data Exporter,
        unless any successor entity has assumed the entire legal obligations of
        the Data Exporter by contract or by operation of law, in which case the
        Data Subject can enforce its rights against such entity. The Data
        Importer may not rely on a breach by a Subprocessor of its obligations
        in order to avoid its own liabilities.
      </p>
      <p>
        3. If a Data Subject is not able to bring a claim against the Data
        Exporter or the Data Importer referred to in paragraphs 1 and 2, arising
        out of a breach by the Subprocessor of any of their obligations referred
        to in Clause 3 or in Clause 11 because both the Data Exporter and the
        Data Importer have factually disappeared or ceased to exist in law or
        have become insolvent, the Subprocessor agrees that the Data Subject may
        issue a claim against the data Subprocessor with regard to its own
        processing operations under the Clauses as if it were the Data Exporter
        or the Data Importer, unless any successor entity has assumed the entire
        legal obligations of the Data Exporter or Data Importer by contract or
        by operation of law, in which case the Data Subject can enforce its
        rights against such entity. The liability of the Subprocessor shall be
        limited to its own processing operations under the Clauses.
      </p>
      <p>
        4. Without prejudice to paragraphs 1, 2 and 3 of Clause 6, each party’s
        aggregate liability to the other under or in connection with these
        Clauses (whether in contract, tort or otherwise) is limited to the
        amount paid for the services by the Customer entity which is party to
        the Agreement in the 12 months immediately preceding the event (or first
        in a series of connected events) giving rise to the liability.
      </p>

      <CenteredItalicizedHeader>Clause 7</CenteredItalicizedHeader>
      <CenteredBoldedHeader>Mediation and jurisdiction</CenteredBoldedHeader>
      <p>
        1. The Data Importer agrees that if the Data Subject invokes against it
        third-party beneficiary rights and/or claims compensation for damages
        under the Clauses, the Data Importer will accept the decision of the
        Data Subject;
      </p>
      <p>
        (a) to refer the dispute to mediation, by an independent person or,
        where applicable, by the supervisory authority;
      </p>
      <p>
        (b) to refer the dispute to the courts in the Member State in which the
        Data Exporter is established.
      </p>
      <p>
        2. The parties agree that the choice made by the Data Subject will not
        prejudice its substantive or procedural rights to seek remedies in
        accordance with other provisions of national or international law.
      </p>

      <CenteredItalicizedHeader>Clause 8</CenteredItalicizedHeader>
      <CenteredBoldedHeader>
        Cooperation with supervisory authorities
      </CenteredBoldedHeader>
      <p>
        1. The Data Exporter agrees to deposit a copy of this contract with the
        supervisory authority if it so requests or if such deposit is required
        under the applicable data protection law.
      </p>
      <p>
        2. The parties agree that the supervisory authority has the right to
        conduct an audit of the Data Importer, and of the Subprocessor, which
        has the same scope and is subject to the same conditions as would apply
        to an audit of the Data Exporter under the applicable data protection
        law.
      </p>
      <p>
        3. The Data Importer shall promptly inform the Data Exporter about the
        existence of legislation applicable to it or any Subprocessor preventing
        the conduct of an audit of the Data Importer, or any Subprocessor,
        pursuant to paragraph 2. In such a case the Data Exporter shall be
        entitled to take the measures foreseen in Clause 5(b).
      </p>

      <CenteredItalicizedHeader>Clause 9</CenteredItalicizedHeader>
      <CenteredBoldedHeader>Governing Law</CenteredBoldedHeader>
      <p>
        The Clauses shall be governed by the law of the Member State in which
        the Data Exporter is established.
      </p>

      <CenteredItalicizedHeader>Clause 10</CenteredItalicizedHeader>
      <CenteredBoldedHeader>Variation of the contract</CenteredBoldedHeader>
      <p>
        The parties undertake not to vary or modify the Clauses. This does not
        preclude the parties from adding clauses on business related issues
        where required as long as they do not contradict the Clause.
      </p>

      <CenteredItalicizedHeader>Clause 11</CenteredItalicizedHeader>
      <CenteredBoldedHeader>Sub-Processing</CenteredBoldedHeader>
      <p>
        1. The Data Importer shall not subcontract any of its processing
        operations performed on behalf of the Data Exporter under the Clauses
        without the prior written consent of the Data Exporter. Where the Data
        Importer subcontracts its obligations under the Clauses, with the
        consent of the Data Exporter, it shall do so only by way of a written
        agreement with the Subprocessor which imposes the same obligations on
        the Subprocessor as are imposed on the Data Importer under the Clauses.
        Where the Subprocessor fails to fulfil its data protection obligations
        under such written agreement the Data Importer shall remain fully liable
        to the Data Exporter for the performance of the Subprocessor’s
        obligations under such agreement.
      </p>
      <p>
        2. The prior written contract between the Data Importer and the
        Subprocessor shall also provide for a third-party beneficiary clause as
        laid down in Clause 3 for cases where the Data Subject is not able to
        bring the claim for compensation referred to in paragraph 1 of Clause 6
        against the Data Exporter or the Data Importer because they have
        factually disappeared or have ceased to exist in law or have become
        insolvent and no successor entity has assumed the entire legal
        obligations of the Data Exporter or Data Importer by contract or by
        operation of law. Such third-party liability of the Subprocessor shall
        be limited to its own processing operations under the Clauses.
      </p>
      <p>
        3. The provisions relating to data protection aspects for sub-processing
        of the contract referred to in paragraph 1 shall be governed by the law
        of the Member State in which the Data Exporter is established.
      </p>
      <p>
        4. The Data Exporter shall keep a list of sub-processing agreements
        concluded under the Clauses and notified by the Data Importer pursuant
        to Clause 5(j), which shall be updated at least once a year. The list
        shall be available to the Data Exporter’s data protection supervisory
        authority.
      </p>

      <CenteredItalicizedHeader>Clause 12</CenteredItalicizedHeader>
      <CenteredBoldedHeader>
        Obligation after the termination of personal data processing services
      </CenteredBoldedHeader>
      <p>
        1. The parties agree that on the termination of the provision of data
        processing services, the Data Importer and the Subprocessor shall, at
        the choice of the Data Exporter, return all the personal data
        transferred and the copies thereof to the Data Exporter or shall destroy
        all the personal data and certify to the Data Exporter that it has done
        so, unless legislation imposed upon the Data Importer prevents it from
        returning or destroying all or part of the personal data transferred. In
        that case, the Data Importer warrants that it will guarantee the
        confidentiality of the personal data transferred and will not actively
        process the personal data transferred anymore.
      </p>
      <p>
        2. The Data Importer and the Subprocessor warrant that upon request of
        the Data Exporter and/or of the supervisory authority, it will submit
        its data processing facilities for an audit of the measures referred to
        in paragraph 1.
      </p>

      <CenteredBoldedHeader>
        Appendix 1 to the Standard Contractual Clauses
      </CenteredBoldedHeader>
      <p>
        This Appendix forms part of the Clauses and must be completed and signed
        by the parties. By signing the signature page of the Data Processing
        Agreement, the parties will be deemed to have signed this Appendix 1.
      </p>

      <div>
        <b>Data exporter</b>
      </div>
      <div>
        The data exporter is the entity identified as <b>“Data Controller”</b>{' '}
        in the Data Processing Agreement
      </div>

      <br />

      <div>
        <b>Data importer</b>
      </div>
      <div>
        The data importer is the entity identified as <b>“Data Processor”</b> in
        the Data Processing Agreement
      </div>

      <br />

      <div>
        <b>Data subjects</b>
      </div>
      <div>
        Data Subjects are defined in Appendix 1 No. 2 of the Data Processing
        Agreement
      </div>

      <br />

      <div>
        <b>Categories of data</b>
      </div>
      <div>
        Categories of data are identified in Appendix 1 No. 3 of the Data
        Processing Agreement
      </div>

      <br />

      <div>
        <b>Processing operations</b>
      </div>
      <div>
        The personal data transferred will be subject to the following basic
        processing activities identified in Appendix 1 No. 1 of the Data
        Processing Agreement.
      </div>

      <CenteredBoldedHeader>
        Appendix 2 to the Standard Contractual Clauses
      </CenteredBoldedHeader>
      <p>
        This Appendix forms part of the Clauses and must be completed and signed
        by the parties. By signing the signature page of the Data Processing
        Agreement, the parties will be deemed to have signed this Appendix 2.
      </p>

      <p>
        <b>
          Description of the technical and organizational security measures
          implemented by the data importer in accordance with Clauses 4(d) and
          5(c) (or document/legislation attached):
        </b>
      </p>

      <p>
        The technical and organizational security measures implemented by the
        data importer are as described in Appendix 2 of the Data Processing
        Agreement.
      </p>
    </Container>
  </Layout>
);

export default DPA;
